import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import Cards from "./cards";
import Paginate from "./paginate";
import { motion } from "framer-motion";
import mdata from "./categories.json";
import Axios from "axios";

import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

const Main = () => {
  const [isResults, setIsResults] = useState(false);
  const [view, setView] = useState("cards");
  const [toTop, setToTop] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);

  const [data, setCdata] = useState(mdata);
  const [isloading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [pnum, setPNum] = useState(1);

  const [udata, setUdata] = useState({
    gender: [],
    occassion: [],
    interests: [],
    typegift: [],
    area: [],
    age: [],
    relation: [],
    budgetFrom: 10,
    budgetTo: 100,
  });

  const hasValueWithLengthOne = Object.values(udata).some((value) => value !== "budget" && value.length >= 1);
  useEffect(() => {
    const sliderEl = document.getElementById("customRange4");
    const sliderEl2 = document.getElementById("customRange5");

    const rangeDistance = 300 - 0;
    const fromPosition = udata.budgetFrom - 0;
    const toPosition = udata.budgetTo - 0;

    sliderEl2.style.background = `linear-gradient(
      to right,
      #ccc 0%,
      #ccc ${(fromPosition / rangeDistance) * 300}%,
      #dc3545 ${(fromPosition / rangeDistance) * 100}%,
      #dc3545 ${(toPosition / rangeDistance) * 100}%, 
      #ccc ${(toPosition / rangeDistance) * 100}%, 
      #ccc 100%)`;
  }, [udata.budgetFrom, udata.budgetTo]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setToTop(true);
      } else {
        setToTop(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(udata);

    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.post("https://admin.flippcadeau.nl/fetch-gift", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        // toast.success("Success");
        setCardData(response.data);
        // setCardData(dummydata);
        setIsResults(true);
        setPNum(1);
        setTimeout(function () {
          window.location.href = "#results";
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false);
        setPNum(1);
        console.log(error);
        // toast.error("Something went Wrong!");
      });
  };

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };
    Axios.post("https://admin.flippcadeau.nl/get-all-categories", JSON.stringify(udata), config)
      .then((response) => {
        console.log(response);
        setCdata(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went Wrong. Please retry!");
      });
  }, []);

  return (
    <>
      {toTop && (
        <button
          className="btn btn-lg toTop fs-1 fw-bold rounded-circle d-flex align-items-center justify-content-center"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Add smooth scrolling behavior
            });
          }}
        >
          <i className="fa fa-angle-up"></i>
        </button>
      )}
      <div className="pattern-container"></div>

      <div className="mainGen d-flex align-items-center p-lg-0 p-3 mb-5 ">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.3 }}
          variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
          className="container rounded bg-white shadow border border-secondary border-1 my-auto p-lg-5 py-4 position-relative"
        >
          <div className="logo">
            <img src="logo.jpg" />
          </div>
          <div className="text-center mb-5 pt-lg-0 pt-4">
            <h1 className="fs-2 fw-bold">Generator</h1>
            <h2 className="text-wrap w-75 mx-auto subtext">Start de cadeaugenerator en vind het perfecte cadeau, voor elk persoon en elke gelegenheid</h2>
          </div>
          <p> Voor wie en welke gelegenheid zoek je een cadeau? Kies minimaal 1 filter</p>
          <form className="form " onSubmit={handleSubmit}>
            <div className="row mb-lg-5 mb-0">
              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-user me-2"></i>{" "}
                    {udata.gender.length !== 0
                      ? udata.gender.map((ini) => {
                          return ini + ",";
                        })
                      : "Geslacht"}
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "gender")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`gender${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.gender.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`gender${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`gender${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.gender];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, gender: newInterests });
                                      }}
                                      checked={udata.gender.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-birthday-cake me-2"></i> Gelegenheid
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "occasion")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`occasion${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.occassion.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`occasion${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`occasion${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.occassion];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, occassion: newInterests });
                                      }}
                                      checked={udata.occassion.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
                <div className={`w-100 slim-scroll ${udata.occassion.length !== 0 ? "mb-4" : "mb-0"} `} style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.occassion.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>

              <div className="col-12 col-lg-3 ">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-book me-2"></i> Interesse(s)
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "interest")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`interests${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.interests.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`interests${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`interests${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.interests];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, interests: newInterests });
                                      }}
                                      checked={udata.interests.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>

                <div className="w-100 slim-scroll" style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.interests.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-gift me-2"></i> Soort cadeau
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "typofgift")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`typofgift${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.typegift.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`typofgift${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`typofgift${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.typegift];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, typegift: newInterests });
                                      }}
                                      checked={udata.typegift.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
                <div className="w-100 slim-scroll " style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.typegift.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-map-marker me-2"></i> Omgeving
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "area")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`area${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.area.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`area${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`area${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.area];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, area: newInterests });
                                      }}
                                      checked={udata.area.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
                <div className={`w-100 slim-scroll ${udata.occassion.length !== 0 ? "mb-4" : "mb-0"} `} style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.area.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-calendar-times-o me-2"></i>Leeftijd
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "age")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`age${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.age.includes(`${jini.ageFrom}-${jini.ageTo}`) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={"fa fa-calendar-times-o fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`age${k}`}>
                                        {jini.ageFrom} - {jini.ageTo}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={`${jini.ageFrom}-${jini.ageTo}`}
                                      id={`age${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.age];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, age: newInterests });
                                      }}
                                      checked={udata.age.includes(`${jini.ageFrom}-${jini.ageTo}`) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
                <div className={`w-100 slim-scroll ${udata.occassion.length !== 0 ? "mb-4" : "mb-0"} `} style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.age.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div class="dropdown">
                  <button
                    class="btn btn-danger p-3 btn-lg dropdown-toggle mb-3 border-1 border border-dark rounded fs-6 w-100 d-flex align-items-center justify-content-between"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <i className="fs-3 fa fa-heart me-2"></i>
                    Relatie
                  </button>

                  <ul class="dropdown-menu w-100 no-scroll border-1 border-dark mt-1" aria-labelledby="dropdownMenuButton">
                    {data
                      .filter((e) => e.type == "relation")
                      .map((ini) => {
                        return ini.data_set.map((jini, k) => {
                          return (
                            <li>
                              <a class="dropdown-item" href="#">
                                <label for={`relation${k}`} style={{ width: "100%", cursor: "pointer" }}>
                                  <div class={`form-check d-flex justify-content-between px-0 w-100 ${udata.relation.includes(jini.name) ? "text-danger" : ""}`}>
                                    <span className=" d-flex align-items-center w-75">
                                      <i className={jini.icon + " fs-3 me-2 w-25"}></i>
                                      <label class="form-check-label text-capitalize" for={`relation${k}`}>
                                        {jini.name}
                                      </label>
                                    </span>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={jini.name}
                                      id={`relation${k}`}
                                      onClick={(e) => {
                                        const newInterests = [...udata.relation];
                                        const interestIndex = newInterests.indexOf(e.target.value);

                                        if (interestIndex === -1) {
                                          // Item doesn't exist in the array, so add it.
                                          newInterests.push(e.target.value);
                                        } else {
                                          // Item exists in the array, so remove it.
                                          newInterests.splice(interestIndex, 1);
                                        }

                                        setUdata({ ...udata, relation: newInterests });
                                      }}
                                      checked={udata.relation.includes(jini.name) ? true : false}
                                    />
                                  </div>
                                </label>
                              </a>
                            </li>
                          );
                        });
                      })}
                  </ul>
                </div>
                <div className={`w-100 slim-scroll ${udata.occassion.length !== 0 ? "mb-4" : "mb-0"} `} style={{ maxHeight: "60px", overflowY: "scroll" }}>
                  {udata.relation.map((ini) => {
                    return <span class="badge rounded-pill text-danger border-1 mb-1 border-danger border me-1 shadow-sm">{ini}</span>;
                  })}
                </div>
              </div>

              <div className="col-12 col-lg-3">
                <div className="w-100 position-relative">
                  <label class="form-label fw-bold fs-6 w-100" for="customRange4">
                    <div className=" d-flex justify-content-between w-100 pe-3">
                      <p>Prijs</p>
                      <p>
                        €{udata.budgetFrom} - €{udata.budgetTo}
                      </p>
                    </div>
                  </label>

                  <div className="durange">
                    <input
                      type="range"
                      class="custom-range"
                      id="customRange4"
                      min={1}
                      onChange={(e) => {
                        setUdata({ ...udata, budgetFrom: e.target.value >= udata.budgetTo - 1 ? parseInt(udata.budgetFrom) : parseInt(e.target.value) });
                      }}
                      value={udata.budgetFrom}
                    />

                    <input
                      type="range"
                      class="custom-range custom-2"
                      id="customRange5"
                      max={300}
                      onChange={(e) => {
                        setUdata({ ...udata, budgetTo: e.target.value > udata.budgetFrom + 1 ? parseInt(e.target.value) : parseInt(udata.budgetFrom) + 1 });
                      }}
                      value={udata.budgetTo}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 text-lg-end">
              {!hasValueWithLengthOne && udata.age.length == "0" ? (
                <>
                  <button type="submit" class="btn btn-danger p-3 d-flex align-items-center ms-lg-auto fs-5 btn-lg border-dark mt-5" disabled>
                    <i className="fa fa-search fs-3 me-3"></i> Start de generator
                  </button>
                </>
              ) : (
                <>
                  {!isloading ? (
                    <button type="submit" class="btn btn-danger p-3 d-flex align-items-center ms-lg-auto fs-5 btn-lg border-dark mt-5">
                      <i className="fa fa-search fs-3 me-3"></i> Start de generator
                    </button>
                  ) : (
                    <button type="button" class="btn btn-danger p-3 d-flex align-items-center ms-lg-auto fs-5 btn-lg border-dark mt-5" disabled>
                      <i class="fa fa-circle-o-notch fa-spin me-2"></i> Loading
                    </button>
                  )}
                </>
              )}
            </div>
          </form>
        </motion.div>
      </div>

      {/* Results */}

      <div className="container" id="results">
        {isResults && (
          <>
            <div className="text-start mb-5">
              <h1 className="fs-1 " style={{ fontWeight: "700" }}>
                Jouw resultaten
              </h1>
              <h2 className="text-wrap fs-1 " style={{ fontWeight: "400" }}>
                Vind het beste cadeau!
              </h2>
            </div>
            <p>{cardData.length} resultaat weergegeven</p>

            <div className="mb-1 text-end w-100">
              <button className={`btn btn-lg ${view == "cards" ? "btn-dark" : "btn-outline-dark"} p-1 px-2 me-1`} onClick={() => setView("cards")}>
                <i class="fa fa-list-alt"></i>
              </button>
              <button className={`btn btn-lg ${view == "cards" ? "btn-outline-dark" : "btn-dark"} p-1 px-2`} onClick={() => setView("list")}>
                <i class="fa fa-th-list"></i>
              </button>
            </div>

            <div className="row">
              {/* {[...Array(7)].map(() => {
              return (
                <div className={`col-lg-${view == "cards" ? "4" : "11"} col-12 mb-3`}>
                  <Cards views={view} />
                </div>
              );
            })} */}

              {cardData !== undefined && cardData.length !== 0 && (
                <>
                  {cardData.slice(pnum * 9 - 9, pnum * 9).map((ini) => {
                    return (
                      <div className={`col-lg-${view == "cards" ? "4" : "11"} col-12 mb-3`}>
                        <Cards views={view} res={ini} />
                      </div>
                    );
                  })}
                </>
              )}
              {cardData.length != 0 && (
                <div className="col-12 text-center">
                  <Paginate pagecount={(e) => setPNum(e)} total={cardData.length} cactive={pnum} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Main;

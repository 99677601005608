import React from "react";
import { motion } from "framer-motion";
const Cards = (props) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false }}
      transition={{ duration: 0.3 }}
      variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
      class={`card w-100 me-4 mb-3 border-1 border-dark p-2 shadow-sm h-100  ${props.views !== "cards" ? "flex-lg-row card-size" : ""} `}
    >
      {props.views !== "cards" ? (
        <img
          class="card-img-top detail-card"
          src={
            props.res.product_image_url
              ? props.res.product_image_url
              : "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cmFuZG9tfGVufDB8fDB8fHww"
          }
          alt="Card image cap"
        />
      ) : (
        <img
          class="card-img-top "
          src={
            props.res.product_image_url
              ? props.res.product_image_url
              : "https://images.unsplash.com/photo-1481349518771-20055b2a7b24?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cmFuZG9tfGVufDB8fDB8fHww"
          }
          alt="Card image cap"
          style={{ height: "100%", objectFit: "cover", maxHeight: "300px" }}
        />
      )}
      <div class="card-body" style={{ position: "relative" }}>
        <div className="d-flex align-items-center justify-content-between">
          <h5 class="card-title fw-bold w-75 pe-3" style={{ WebkitLineClamp: 2, overflow: "hidden", display: "-webkit-box", WebkitBoxOrient: "vertical" }}>
            {props.res.product_title}
          </h5>
          <h6 class="card-title fw-bold">{props.res.product_price_label}</h6>
        </div>

        <div className="text-truncate-container pe-1 my-1">
          <p
            class="card-text slim-scroll pe-1"
            style={{ WebkitLineClamp: props.views == "cards" ? "4" : "100", maxHeight: props.views == "cards" ? "auto" : "180px", overflowY: props.views == "cards" ? "hidden" : "auto" }}
          >
            {props.res.product_content}
          </p>
        </div>

        <div className={`text-end w-100  ${props.views !== "cards" ? "position-absolute" : ""}`} style={{ right: "2%", bottom: "5%" }}>
          <a href={props.res.product_url} target="_blank" class="btn p-2 btn-danger my-2 fw-bold border  border-1 border-dark d-flex align-items-center ms-auto w-50">
            <i className="fa fa-eye fs-5 me-2"></i> <span className="w-100 text-center"> Bekijken </span>
          </a>
          <a href="https://flippcadeau.nl/contact" className="link text-dark pt-2" target="_blank">
            <em>Werkt de link niet? Laat het ons weten!</em>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default Cards;
